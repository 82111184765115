import { ChevronRightIcon } from 'lucide-react';
import moment from 'moment';
import { MouseEventHandler } from 'react';
import { analyticsTrackEvent } from '../../core/analytics';

interface NewsItemPages {
  page: number;
  per_page: number;
  total_pages: number;
  type: string;
}

interface NewsItem {
  id: string;
  type: string;
  workspace_id: string;
  title: string;
  body: string;
  sender_id: number;
  state: string;
  labels: any[];
  cover_image_url: string | null;
  reactions: any[];
  deliver_silently: boolean;
  created_at: number;
  updated_at: number;
  newsfeed_assignments: any[];
}

export interface NewsFeedResponse {
  type: string;
  pages: NewsItemPages;
  data: NewsItem[];
  total_count: number;
}

function NewFeedItem({
  title,
  date,
  imageUrl,
  bodyHtml,
  isFirst,
  isLast,
}: {
  title: string;
  date: string;
  imageUrl: string | null;
  bodyHtml: string;
  isFirst?: boolean;
  isLast?: boolean;
}) {
  // Add an imperative handler to track clicks on any link in the HTML of the news feed item
  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'A') {
      analyticsTrackEvent('Login - News Feed', {
        action: 'Link clicked',
        title: title,
        href: (target as HTMLAnchorElement).href,
      });
    }
  };

  return (
    <div className="flex gap-4 items-start relative pb-16">
      {!isLast && (
        <div className="absolute left-[6px] top-2 bottom-0 w-px h-full bg-violet-200 z-0"></div>
      )}
      <div className="size-[13px] bg-violet-400 mt-1.5 rounded-full border-violet-200 border-[3px] ring-4 ring-purple-50 z-10"></div>
      <div className="flex-1 gap-1">
        <div className="text-sm font-medium text-violet-900">{title}</div>
        <div className="text-xs font-medium text-violet-400">{date}</div>
        {imageUrl && (
          <div className="border inline-block mt-4 rounded shadow-sm overflow-hidden border-violet-100">
            <img alt="Cover image" src={imageUrl} className="h-40 w-auto " />
          </div>
        )}
        <div
          onClick={handleClick}
          className="prose prose-violet-900 prose-sm prose-a:!text-violet-900/50 prose-a:text-xs prose-a:!underline text-violet-900 mt-4"
          dangerouslySetInnerHTML={{ __html: bodyHtml }}
        />
      </div>
    </div>
  );
}

export function NewsFeed({ items }: { items: NewsFeedResponse['data'] }) {
  return (
    <div className="max-w-lg w-full space-y-12 mx-auto">
      <h1 className="text-lg font-medium text-violet-900">What's new in Solv?</h1>
      <div className="flex flex-col">
        {items.slice(0, 2).map((item, i) => (
          <NewFeedItem
            key={item.id}
            title={item.title}
            date={moment(item.created_at * 1000).format('MMMM D')}
            imageUrl={item.cover_image_url}
            bodyHtml={item.body}
            isFirst={i === 0}
          />
        ))}
        <div className="flex gap-4 items-start relative pb-16">
          <div className="size-[13px] bg-violet-400 mt-1.5 rounded-full border-violet-200 border-[3px] ring-4 ring-purple-50 z-10"></div>
          <div className="flex-1 gap-1">
            <a
              href="https://updates.solvhealth.com"
              target="_blank"
              className="text-xs underline !text-violet-900/50 hover:!text-violet-500 hover:underline appearance-none bg-transparent mt-1 block"
              rel="noreferrer"
              onClick={() => analyticsTrackEvent('Login - News Feed', { action: 'See all' })}
            >
              See all updates <ChevronRightIcon className="size-3 -mb-0.5 inline-block" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
